import styled from 'styled-components'
import {BASE_CONF} from 'components/common/CPTheme'

export const Wrapper = styled.div`
  padding: ${props => props.padding};
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: ${props => props.spacing.base}px;
  }

  @media only screen and ${BASE_CONF.screens.xs} {
    ${props =>
      typeof props.spacing.xs !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.xs}px;
    }
    `
        : null}
  }

  @media only screen and ${BASE_CONF.screens.sm} {
    ${props =>
      typeof props.spacing.sm !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.sm}px;
    }
    `
        : null}
  }

  @media only screen and ${BASE_CONF.screens.md} {
    ${props =>
      typeof props.spacing.md !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.md}px;
    }
    `
        : null}
  }

  @media only screen and ${BASE_CONF.screens.lg} {
    ${props =>
      typeof props.spacing.lg !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.lg}px;
    }
    `
        : null}
  }

  @media only screen and ${BASE_CONF.screens.xl} {
    ${props =>
      typeof props.spacing.xl !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.xl}px;
    }
    `
        : null}
  }

  @media only screen and ${BASE_CONF.screens.xxl} {
    ${props =>
      typeof props.spacing.xxl !== 'undefined'
        ? `
    > *:not(:last-child) {
      margin-bottom: ${props.spacing.xxl}px;
    }
    `
        : null}
  }
`
