import React from 'react'

import * as S from './Styled'

function HStack({className, spacing = {base: 0}, p = 0, children, ...otherProps}) {
  const formattedSpacing = typeof spacing === 'object' ? spacing : {base: spacing}
  const formattedPadding = typeof p === 'number' ? `${p}px` : p

  return (
    <S.Wrapper
      {...otherProps}
      className={className}
      padding={formattedPadding}
      spacing={formattedSpacing}
    >
      {children}
    </S.Wrapper>
  )
}

export default HStack
