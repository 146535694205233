import styled from 'styled-components'
import HStack from 'components/common/Layout/HStack'

export const Wrapper = styled(HStack)`
  padding: 32px;
  justify-content: space-between;
  flex-direction: row-reverse;
`

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`
