import styled from 'styled-components'

export const TopWrapper = styled.div``

export const Wrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
`

export const ContentWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 600px);
  min-height: 279px;
  border-radius: 24px;
  background: white;
  margin: auto;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  display: grid;

  ${props => props.theme.mediaQuery.md} {
    max-height: calc(100% - 96px);
    width: min(80%, 600px);
  }
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.64);
`
