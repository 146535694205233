import React, {useRef, forwardRef} from 'react'

import * as S from './Styled'

const VStack = forwardRef((props, ref) => {
  const {className, spacing = {base: 0}, p = 0, children, ...otherProps} = props
  const formattedSpacing = typeof spacing === 'object' ? spacing : {base: spacing}
  const formattedPadding = typeof p === 'number' ? `${p}px` : p

  const contentRef = typeof ref !== 'undefined' ? ref : useRef()

  return (
    <S.Wrapper
      {...otherProps}
      ref={contentRef}
      className={className}
      padding={formattedPadding}
      spacing={formattedSpacing}
    >
      {children}
    </S.Wrapper>
  )
})

export default VStack
