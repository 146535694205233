import {ButtonPrimary} from 'components/common/buttons'
import React, {useCallback} from 'react'
import useGenericModal from '../useGenericModal'
import Header from './components/Header'

import * as S from './Styled'

const DEFAULT_MODAL_ID = 'cp-info-modal'

function useInfoModal(options = {}) {
  const modalId = options.id || DEFAULT_MODAL_ID

  const {Modal: GenericModal, isShow, hide, ...rest} = useGenericModal({
    clickOutsideToHide: true,
    id: modalId,
    ...options
  })

  const Modal = useCallback(
    ({
      Icon = null,
      children,
      onButtonClick = () => {},
      buttonText = 'CONFIRM',
      Button = ButtonPrimary
    }) => (
      <>
        <GenericModal>
          <Header onClose={hide} />
          {Icon ? (
            <S.IconWrapper>
              <Icon />
            </S.IconWrapper>
          ) : null}
          <S.ContentWrapper className="cp-info-modal-content-wrapper">{children}</S.ContentWrapper>
          <S.ButtonWrapper className="cp-info-modal-button-wrapper">
            <Button text={buttonText} onClick={onButtonClick} />
          </S.ButtonWrapper>
        </GenericModal>
        <S.GlobalStyle modalId={modalId} styleOverride={options.styleOverride} />
      </>
    ),
    [isShow]
  )

  return {Modal, isShow, hide, ...rest}
}

export default useInfoModal
