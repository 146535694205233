import React from 'react'

import * as S from './Styled'

function Header({onClose}) {
  return (
    <S.Wrapper className="cp-modal-content-header-wrapper">
      <S.CloseIcon
        className="cp-modal-close-button"
        alt="modal-close-btn"
        src="/static/images/modal-close.svg"
        onClick={onClose}
      />
    </S.Wrapper>
  )
}

export default Header
