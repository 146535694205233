import styled from 'styled-components'
import ButtonBasic from './basic'

const DangerButtonPrimary = styled(ButtonBasic)`
  color: ${props => props.theme.colors.white};
  background-color: ${props =>
    props.isDisabled ? props.theme.colors.primary2Disabled : props.theme.colors.primary2};

  :hover {
    ${props => (props.isDisabled ? '' : `background-color: ${props.theme.colors.primary2Hover};`)}
  }
`

export default DangerButtonPrimary
