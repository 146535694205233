import React, {useCallback} from 'react'

import usePortal from 'react-cool-portal'

import * as S from './Styled'

const DEFAULT_MODAL_ID = 'cp-generic-modal'

function useGenericModal(options = {}) {
  const {Portal, isShow, hide, ...otherPortalProps} = usePortal({
    defaultShow: false,
    ...options
  })

  const handleOnBackdropClick = useCallback(() => {
    if (options.clickOutsideToHide) {
      hide()
    }
  }, [options.clickOutsideToHide])

  const Modal = useCallback(
    ({children}) => (
      <Portal>
        <S.TopWrapper id={options.id || DEFAULT_MODAL_ID}>
          <S.Wrapper className="cp-modal-generic-wrapper" tabIndex={-1}>
            <S.Backdrop className="cp-modal-generic-backdrop" onClick={handleOnBackdropClick} />
            <S.ContentWrapper className="cp-modal-generic-content-wrapper">
              {children}
            </S.ContentWrapper>
          </S.Wrapper>
        </S.TopWrapper>
      </Portal>
    ),
    [isShow]
  )

  return {Modal, isShow, hide, ...otherPortalProps}
}

export default useGenericModal
