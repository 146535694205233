import styled, {createGlobalStyle} from 'styled-components'

import VStack from 'components/common/Layout/VStack'

export const GlobalStyle = createGlobalStyle`
  #react-cool-portal {
    #${props => props.modalId} {
      .cp-modal-generic-content-wrapper {
        grid-template: auto auto 1fr auto / 1fr;
      }
      ${props => props.styleOverride}
    }
  }
`

export const ContentWrapper = styled(VStack)`
  padding: 0 16px 24px 16px;
  text-align: center;

  ${props => props.theme.mediaQuery.md} {
    padding: 0 64px 24px 64px;
  }
`

export const Icon = styled.img`
  width: 56px;
  height: 56px;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
`
